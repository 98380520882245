import { twMerge } from 'tailwind-merge';

import { formatDateToDateTimeDisplay } from 'common/helpers/format';
import { Task } from 'models/link/Link';

type Props = {
    ticket: Task;
};

function TicketCard(props: Props) {
    const { ticket } = props;

    return (
        <div className="flex flex-col overflow-hidden rounded border px-4 py-4 shadow-lg">
            <div>
                <p className="text-sm font-medium text-neutral-500">Ticket</p>
                <p className="ml-4 font-bold">{ticket.ticketNO}</p>
            </div>
            <div>
                <p className="text-sm font-medium text-neutral-500">Creator</p>
                <p className="ml-4 font-bold">{ticket.creator}</p>
            </div>

            <p className="mt-4">{ticket.jobdescription}</p>
            {ticket.ticketDetail !== '' && <p>Detail: {ticket.ticketDetail}</p>}

            <div className="mt-6">
                <p className="text-sm font-medium text-neutral-500">Status</p>
                <span
                    className={twMerge(
                        'ml-4 rounded-md border border-zinc-300 px-2 font-bold',
                        ticket.activityOverviewStatus === 'ถูกยกเลิก'
                            ? 'border-red-600 bg-red-50 text-red-600'
                            : ticket.activityOverviewStatus === 'ปิดงานแล้ว'
                            ? 'border-green-600 bg-green-50 text-green-600'
                            : ticket.activityOverviewStatus === 'กำลังดำเนินการ'
                            ? 'border-blue-600 bg-blue-50 text-blue-600'
                            : ''
                    )}
                >
                    {ticket.activityOverviewStatus + ' ' + ticket.taskStatusDesc}
                </span>
            </div>

            <div className="mt-2 flex gap-2">
                <span className="inline-block whitespace-nowrap rounded-full bg-gray-200 px-3 py-1 text-sm font-semibold text-gray-700">
                    {formatDateToDateTimeDisplay(ticket.checkIn_Date + ticket.checkIn_Time)}
                </span>
                <span className="inline-block whitespace-nowrap rounded-full bg-gray-200 px-3 py-1 text-sm font-semibold text-gray-700">
                    Work Group : {ticket.projectcategory}
                </span>
            </div>
        </div>
    );
}

export default TicketCard;
