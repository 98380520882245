import React, { useState, useEffect, useCallback, useDeferredValue } from 'react';
//import axios, { AxiosError } from 'axios';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import Marquee from 'react-fast-marquee';
import { useWindowSize } from 'usehooks-ts';
import {
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    IconButton,
    useBoolean
} from '@chakra-ui/react';

// Icons
import { MdOutlineMenu } from 'react-icons/md';
import { getCurrentDateTime, parseDateString, parseTimeString } from 'common/helpers';
import { useStore } from 'common/hooks';
import logo from 'images/f1-new-logo.png';
import { TaskStore as TaskStoreType } from 'models/taskStore/Task';

import ApplicationAccordions from './ApplicationAccordions';
import MenuIcons from './TopNavigation/MenuIcons';

//import { UserInformationResponse } from 'models/citizen/Citizen';

function TopNavigation() {
    const dimensions = useDeferredValue(useWindowSize());

    const [showMenuDrawer, setShowMenuDrawer] = useBoolean();

    const { versionStore, userCompanyStore, companyMasterStore, taskStore, userApplicationStore } = useStore();
    const { userName, citizenId, userRole, email } = userCompanyStore;
    const { applicationVersion } = versionStore;
    const taskScheduleList = taskStore.taskScheduleList;

    useEffect(() => {
        fetchVersion();
        getUserInformation('', userName, 'active');
        fetchCompanies();
        fetchTasks();
        fetchUserAppAllData()

        //fetchCitizen();
    }, []);

    const fetchVersion = useCallback(async () => {
        await versionStore.fetchApplicationBuildVersion();
    }, []);

    const getUserInformation = useCallback(
        async (sid: string, citizenId: string, status: string) => {
            await userCompanyStore.fetchUserCompany(sid, citizenId, status);
        },
        []
    );

    const fetchCompanies = async () => {
        await companyMasterStore.fetchCompanyMasterStore('', 'active');
    };

    const fetchTasks = async () => {
        const dateTimeNow = getCurrentDateTime();
        await taskStore.fetchTasksSchedule(dateTimeNow, false);
    };

    const createTaskAnnounce = (task: TaskStoreType) => {
        const startDateTime =
            parseDateString(task.start_date_time.substring(0, 8)) +
            ' ' +
            parseTimeString(task.start_date_time.substring(8, 14));
        const endDateTime =
            parseDateString(task.end_date_time.substring(0, 8)) +
            ' ' +
            parseTimeString(task.end_date_time.substring(8, 14));

        /*return task.subject + ' เริ่มต้น ' + startDateTime + ' สิ้นสุด ' + endDateTime;*/
        return task.subject;
    };

    const fetchUserAppAllData = async () => {
        await userApplicationStore.fetchUserApplications('');
    };

    return (
        <header className="flex">
            {/* Brand */}
            <Link
                to="/"
                className={twMerge(
                    'relative hidden min-w-[360px] cursor-pointer overflow-hidden bg-[#EEEEEE] py-2 lg:flex',
                    'after:absolute after:right-0 after:top-0 after:h-0 after:w-0 after:border-b-[50px] after:border-r-[50px] after:border-t-0 after:border-solid',
                    "after:border-b-transparent after:border-l-transparent after:border-r-[#1C2F36] after:border-t-transparent after:content-['']"
                )}
            >
                <img
                    className="mx-auto h-[33px] w-[76px]"
                    src={logo}
                    alt="F1"
                />
            </Link>

            {/* Content */}
            <div className="flex flex-1 items-center justify-between bg-[#1C2F36] px-[15px]">
                <Link
                    to="/"
                    className="flex flex-col gap-x-6 lg:flex-row lg:items-center"
                >
                    <span className="text-[20px] font-bold text-white">
                        focus<span className="text-[#f39267]">O</span>ne ERP
                    </span>

                    <span className="text-[14px] font-bold text-[#E55C00]">
                        version {applicationVersion}
                    </span>
                </Link>

                <div className="mx-10 hidden flex-1 xl:block">
                    <Marquee
                        className="rounded-[5px] border border-neutral-700 py-1"
                        speed={30}
                        gradient={false}
                        pauseOnHover
                    >
                        {(!taskScheduleList || taskScheduleList.length === 0) && (
                            <span className="text-white">-</span>
                        )}

                        {taskScheduleList &&
                            taskScheduleList.length > 0 &&
                            taskScheduleList.map((task, index) => {
                                if (task.type !== 'announcement') {
                                    return <React.Fragment key={index}></React.Fragment>;
                                }

                                return (
                                    <span
                                        key={index}
                                        className="mr-14 text-red-400"
                                    >
                                        {createTaskAnnounce(task)}
                                    </span>
                                );
                            })}
                    </Marquee>
                </div>

                {/* Right icons */}
                {dimensions.width < 1024 ? (
                    <React.Fragment>
                        <IconButton
                            size="md"
                            variant="unstyled"
                            aria-label="Main Menu"
                            icon={<MdOutlineMenu size={32} />}
                            onClick={setShowMenuDrawer.on}
                            className="relative left-2 text-white"
                        />
                        <Drawer
                            size="md"
                            isOpen={showMenuDrawer}
                            placement="right"
                            onClose={setShowMenuDrawer.off}
                        >
                            <DrawerOverlay />
                            <DrawerContent>
                                <DrawerCloseButton className="text-white" />
                                <DrawerHeader className="bg-[#1C2F36] py-0">
                                    <div className="flex items-center gap-4">
                                        <span className="text-xl font-bold text-white">MENU</span>
                                        <div className="h-8 w-[2px] bg-white" />
                                        <div className="flex flex-col">
                                            <span className="text-[20px] font-bold text-white">
                                                focus<span className="text-[#f39267]">O</span>ne ERP
                                            </span>
                                            <span className="text-[14px] font-bold text-[#E55C00]">
                                                version {applicationVersion}
                                            </span>
                                        </div>
                                    </div>
                                </DrawerHeader>
                                <DrawerBody className="p-0">
                                    <div className="bg-[#1C2F36] px-6">
                                        <MenuIcons />
                                    </div>
                                    <ApplicationAccordions
                                        onClickApplication={setShowMenuDrawer.off}
                                    />
                                </DrawerBody>
                            </DrawerContent>
                        </Drawer>
                    </React.Fragment>
                ) : (
                    <MenuIcons />
                )}
            </div>
        </header>
    );
}

export default observer(TopNavigation);
