import React, { useCallback, useMemo, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import {
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuGroup,
    MenuItem,
    MenuList,
    useBoolean,
    useConst
} from '@chakra-ui/react';
import { observer } from 'mobx-react';
import Swal from 'sweetalert2';
import { twMerge } from 'tailwind-merge';
import { BsGrid3X3Gap, BsTicketPerforated } from 'react-icons/bs';
import { AiFillCaretDown, AiOutlineQuestionCircle } from 'react-icons/ai';
import { IoNotificationsOutline } from 'react-icons/io5';
import { PiChatsLight } from 'react-icons/pi';
import { FiList, FiUserCheck, FiUserPlus, FiUsers } from 'react-icons/fi';
import { MdOutlineAnnouncement, MdOutlineAssignmentInd } from 'react-icons/md';
import { BiTask } from 'react-icons/bi';
import { TbUsersGroup, TbReport, TbHeartRateMonitor } from 'react-icons/tb';

import { useLoader, useStore } from 'common/hooks';
import { Modal, ModalHeader } from 'common/materials';
import { extractAccessToken } from 'common/helpers';
import UserCompanyMappingComponent from 'components/user/components/UserCompanyMappingComponent';
import CreateTaskComponent from 'components/task/components/CreateTaskComponent';
import { getFocusOneHelpToken } from 'data/focusOneHelp/repositories/focusOneHelp.repository';
import { getToken } from 'data/token/repositories/tokenRepository';

import SpeedTestPopover from './SpeedTestPopover';
import ProfilePopover from './ProfilePopover';
import LineConnectPreparationModal from './LineConnectPreparationModal';
import LineConnectOnboardModal from './LineConnectOnboardModal';
import NewTaskModal from './NewTaskModal';
import SupportModal from './SupportModal';

import { UserInformationResponse } from 'models/citizen/Citizen';

function MenuIcons() {
    const history = useHistory();

    const [showModalPreparationLineConnect, setShowModalPreparationLineConnect] = useBoolean();
    const [showModalSupport, setShowModalSupport] = useBoolean();
    const [showModalLineConnect, setShowModalLineConnect] = useBoolean();
    const [showModalNewTask, setShowModalNewTaskPopup] = useBoolean();
    const [showModalUserRegisterPopup, setShowModalUserRegisterPopup] = useBoolean();
    const [showModalCreateTaskSchedulePopup, setShowModalCreateTaskSchedulePopup] = useBoolean();

    const loader = useLoader();
    const extra = useConst(extractAccessToken());
    const { companyMasterStore, userCompanyStore, profileStore, userApplicationStore } = useStore();
    const { userName, userRole, email } = userCompanyStore;
    const { companyMaster } = companyMasterStore;
    const { userInformation } = profileStore;
    const windowRef = useRef<ReturnType<typeof window.open>>();
    const MonitorApplication = userApplicationStore.monitorApplication

    const [isSCG, setIsSCG] = useState<boolean>(false);

    useEffect(() => {
        profileStore.fetchCitizen();
        fetchIsSCG();
    }, []);

    const fetchIsSCG = async () => {
        const data = (await axios.get('/v1/link-sid')).data;
        setIsSCG(data.link_sid === 'SCG');
    };

    //console.log('ngame user role', userRole);

    const menuGroups = useMemo(
        () => [
            {
                allowed: ['Super Admin'],
                menus: [
                    {
                        to: '/feed-task',
                        Icon: FiList,
                        label: 'Feed Task',
                        tailwindClasses: '-left-0.5'
                    },
                    {
                        to: '#',
                        onClick: setShowModalCreateTaskSchedulePopup.toggle,
                        Icon: MdOutlineAnnouncement,
                        label: 'New Announcement',
                        tailwindClasses: '-left-0.5'
                    },
                    {
                        to: '#',
                        onClick: setShowModalNewTaskPopup.toggle,
                        Icon: BiTask,
                        label: 'New Task',
                        tailwindClasses: '-left-0.5'
                    }
                ]
            },
            {
                allowed: ['Super Admin', 'Admin'],
                menus: [
                    {
                        to: '/user-register',
                        Icon: FiUserPlus,
                        label: 'User Register',
                        tailwindClasses: ''
                    },
                    {
                        to: '/user-verify',
                        Icon: FiUserCheck,
                        label: 'User Verification',
                        tailwindClasses: ''
                    },
                    {
                        to: '/assign-role-profile',
                        Icon: MdOutlineAssignmentInd,
                        label: 'Assign Role Profile',
                        tailwindClasses: '-left-0.5'
                    },
                    {
                        to: '/assign-employee',
                        Icon: TbUsersGroup,
                        label: 'Assign Employee',
                        tailwindClasses: '-left-0.5'
                    },
                    {
                        to: '/report-log',
                        Icon: TbReport,
                        label: 'Report Logs',
                        tailwindClasses: '-left-0.5'
                    }
                ]
            },
            {
                allowed: ['Super Admin', 'Admin', 'User'],
                menus: [
                    {
                        to: '#',
                        onClick: setShowModalUserRegisterPopup.toggle,
                        Icon: FiUsers,
                        label: 'User Company Mapping',
                        tailwindClasses: ''
                    }
                ]
            }
        ],
        [
            setShowModalCreateTaskSchedulePopup,
            setShowModalNewTaskPopup,
            setShowModalUserRegisterPopup
        ]
    );

    const getHelpToken = async () => {
        const getHelpTokenResponse = await getFocusOneHelpToken();
        console.log(getHelpTokenResponse);

        return getHelpTokenResponse[1] ?? '';
    };

    const getAccessToken = async (companyToken: string) => {
        const getTokenResponse = await getToken({
            token: companyToken,
            citizen_id: extra.citizen_id,
            email: extra.email,
            first_name: extra.first_name,
            last_name: extra.last_name
        });
        console.log(getTokenResponse);
        return getTokenResponse[1] ?? '';
    };

    const redirectToFocusOneHelp = async () => {
        loader.show();

        let helpToken: string | undefined = undefined;
        let accessToken = '';

        //if (userApplicationMapping.length > 0) {
        //    companyToken = getCompanyTokenDemo(userApplicationMapping);
        //}

        helpToken = await getHelpToken();
        if (helpToken) {
            accessToken = await getAccessToken(helpToken);
        }

        if (isSCG) {
            window.open('https://f1-help.thailanderp.com/ingress.aspx?token=' + accessToken);
        } else {
            window.open('https://f1-help.thailanderp.com/ingress.aspx?token=' + accessToken);
        }

        loader.hide();
    };

    const handleClickLogout = useCallback(async () => {
        const result = await Swal.fire({
            title: 'คุณต้องการออกจากทุกระบบ Focus One Web Application หรือไม่ ?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'ยืนยัน'
        });

        if (!result.isConfirmed) {
            return;
        }

        history.replace('/logout');
    }, []);

    const openModal = useCallback((_: boolean, isReloadWindow: boolean = false) => {
        setShowModalCreateTaskSchedulePopup.toggle();

        if (isReloadWindow) window.location.reload();
    }, []);

    const openMonitorApplication = (url: string, companyToken: string) => {
        const authToken = localStorage.getItem('SSO_AUTH');
        const credentials = JSON.parse(authToken!);
        const accessToken = credentials.access_token;

        const composedUrlWithToken =
            url + (url.indexOf('?') > -1 ? '&token=' : '?token=') + accessToken;

        console.log(composedUrlWithToken)
        windowRef.current = window.open();
        windowRef.current && (windowRef.current.location = composedUrlWithToken);
    }

    return (
        <div className="flex flex-row items-center">
            <IconButton
                size="md"
                variant="unstyled"
                aria-label="Line Connect"
                className="group"
                icon={
                    <img
                        src="https://focusone-documents.s3.ap-southeast-1.amazonaws.com/line-icon/Line-Icon.png"
                        className="h-8 w-8"
                    />
                }
                onClick={setShowModalPreparationLineConnect.on}
            />

            <IconButton
                size="md"
                variant="unstyled"
                aria-label="Ticket"
                className="group"
                icon={
                    <BsTicketPerforated
                        size={28}
                        className="text-white transition-colors group-hover:text-primary-900"
                    />
                }
                onClick={() => history.push('/ticket')}
            />

            <IconButton
                size="md"
                variant="unstyled"
                aria-label="Ticket"
                className="group"
                icon={
                    <TbHeartRateMonitor
                        size={28}
                        className="text-white transition-colors group-hover:text-primary-900"
                    />
                }
                onClick={() => { openMonitorApplication(MonitorApplication?.application_ingress!, MonitorApplication?.company_token!) }}
            />

            <SpeedTestPopover />

            <IconButton
                size="md"
                variant="unstyled"
                aria-label="Notification"
                className="group"
                icon={
                    <IoNotificationsOutline
                        size={28}
                        className="text-white transition-colors group-hover:text-primary-900"
                    />
                }
            />

            <IconButton
                size="md"
                variant="unstyled"
                aria-label="Question"
                className="group"
                icon={
                    <PiChatsLight
                        size={28}
                        className="text-white transition-colors group-hover:text-primary-900"
                    />
                }
            />

            <Menu
                isLazy
                placement="bottom-end"
            >
                <MenuButton
                    as={IconButton}
                    size="md"
                    aria-label="Menu"
                    variant="unstyled"
                    icon={
                        <BsGrid3X3Gap
                            size={28}
                            className="text-white transition-colors group-hover:text-primary-900"
                        />
                    }
                    className="group"
                />
                <MenuList>
                    <MenuGroup
                        title="F1 ERP ADMIN"
                        className="text-base font-bold"
                    >
                        {menuGroups.reduce<JSX.Element[]>((accumulate, menuGroup, index) => {
                            if (
                                !menuGroup.allowed.includes(userRole) &&
                                !menuGroup.allowed.includes('User')
                            ) {
                                return accumulate;
                            }

                            if (index > 0) {
                                accumulate.push(<MenuDivider key={`divider-${index}`} />);
                            }

                            accumulate.push(
                                <React.Fragment key={`menu-${index}`}>
                                    {menuGroup.menus.map(menu => (
                                        <MenuItem
                                            key={menu.label}
                                            as={Link}
                                            to={menu.to}
                                            icon={
                                                <menu.Icon
                                                    size={20}
                                                    className={twMerge(
                                                        'relative text-primary-900',
                                                        menu.tailwindClasses
                                                    )}
                                                />
                                            }
                                            onClick={menu.onClick}
                                            className="text-sm"
                                        >
                                            {menu.label}
                                        </MenuItem>
                                    ))}
                                </React.Fragment>
                            );

                            return accumulate;
                        }, [])}
                    </MenuGroup>
                </MenuList>
            </Menu>

            <IconButton
                size="md"
                variant="unstyled"
                aria-label="Help"
                className="group"
                icon={
                    <AiOutlineQuestionCircle
                        size={28}
                        className="mr-1 text-white transition-colors group-hover:text-primary-900"
                    />
                }
                onClick={redirectToFocusOneHelp}
            />

            {/* <div className="bg-white w-[1px] h-[25px]" /> */}

            <div className="flex cursor-pointer items-center gap-1 border-l border-l-white pl-2">
                <AiFillCaretDown
                    size={14}
                    className="text-white"
                />
                <span className="text-[14px] text-white">EN</span>
            </div>

            <ProfilePopover
                userName={userName}
                userRole={userRole}
                email={email}
                image={
                    userInformation &&
                        userInformation.image &&
                        userInformation.image.trim().length !== 0
                        ? userInformation.image
                        : undefined
                }
                onClickNavigateToProfile={() => history.push('/profile')}
                onClickLogout={handleClickLogout}
                onClickOpenSupportModal={setShowModalSupport.toggle}
            />

            <LineConnectPreparationModal
                motionPreset="slideInBottom"
                isOpen={showModalPreparationLineConnect}
                onClose={setShowModalPreparationLineConnect.off}
                onClickStartConnect={() => {
                    setShowModalPreparationLineConnect.off();
                    setShowModalLineConnect.on();
                }}
            />
            <LineConnectOnboardModal
                motionPreset="slideInBottom"
                isOpen={showModalLineConnect}
                onClose={setShowModalLineConnect.off}
            />

            <NewTaskModal
                isOpen={showModalNewTask}
                onClose={setShowModalNewTaskPopup.toggle}
            />

            {/* modal User company maping */}
            <Modal
                closeOnClickOverlay
                isOpen={showModalUserRegisterPopup}
                onClose={setShowModalUserRegisterPopup.toggle}
            >
                <ModalHeader>User Company Mapping</ModalHeader>
                <UserCompanyMappingComponent
                    userId={extra.citizen_id}
                    openModal={setShowModalUserRegisterPopup.toggle}
                    companyMaster={companyMaster}
                />
            </Modal>

            {/* modal Create new Task */}
            <Modal
                fullscreen
                closeOnClickOverlay
                isOpen={showModalCreateTaskSchedulePopup}
                onClose={setShowModalCreateTaskSchedulePopup.toggle}
            >
                <ModalHeader>New Announcement</ModalHeader>
                {/* <ModalBody> */}
                <CreateTaskComponent
                    userId={extra.citizen_id}
                    openModal={openModal}
                    activityTypeProp={'announce'}
                    mode={'TaskScheduler'}
                    onClose={setShowModalCreateTaskSchedulePopup.toggle}
                />
                {/* </ModalBody> */}
                {/*<ModalFooter>*/}
                {/*</ModalFooter>*/}
            </Modal>

            {/* Modal Support */}
            <SupportModal
                isOpen={showModalSupport}
                onClose={setShowModalSupport.toggle}
            />
        </div>
    );
}

export default observer(MenuIcons);
