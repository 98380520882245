import React, { useMemo } from 'react';
import {
    Button,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger
} from '@chakra-ui/react';
import { twMerge } from 'tailwind-merge';
import { BiChevronDown } from 'react-icons/bi';
import { FaEnvelope, FaFilter } from 'react-icons/fa';

import { Input } from 'common/materials';
import { Task } from 'models/link/Link';
import { parseDateString } from 'modules/common';

type Props = {
    tasks: Task[] | null;
    onClickTicketDetail: (task: Task) => void;
};

function TicketTable(props: Props) {
    const { tasks, onClickTicketDetail } = props;

    const taskGroups = useMemo(() => {
        if (!tasks) {
            return null;
        }

        const taskGroupMap = {};
        tasks.forEach(task => {
            const key = task.checkIn_Date;
            if (!taskGroupMap[key]) {
                taskGroupMap[key] = [task];
            } else {
                taskGroupMap[key].push(task);
            }
        });

        return Object.keys(taskGroupMap)
            .map(createdOn => ({
                createdOn,
                tasks: taskGroupMap[createdOn] as Task[]
            }))
            .reverse();
    }, [tasks]);

    if (!taskGroups) {
        return null;
    }

    return (
        <div className="w-full overflow-auto [&_*]:text-sm">
            <table className="w-full table-auto">
                <thead>
                    <tr className="sticky top-0 whitespace-nowrap border border-zinc-300 bg-inherit bg-zinc-100 [&_th:not(:first-child)]:px-4 [&_th]:border-l [&_th]:border-l-zinc-300 [&_th]:py-2">
                        <th>Title</th>
                        <th>Ticket No</th>
                        <th>Response Person</th>
                        <th>Activity Status</th>
                        <th>Work Group</th>
                        <th>
                            <div className="flex items-center gap-1">
                                <span>Progress</span>
                                <Popover placement="bottom-end">
                                    <PopoverTrigger>
                                        <IconButton
                                            size="xs"
                                            aria-label="filter-icon"
                                            variant="unstyled"
                                            className="group hover:bg-primary-900"
                                            icon={
                                                <FaFilter
                                                    size={16}
                                                    className="text-primary-900 transition-colors group-hover:text-white"
                                                />
                                            }
                                        />
                                    </PopoverTrigger>
                                    <PopoverContent className="w-fit">
                                        <PopoverArrow />
                                        <PopoverCloseButton />
                                        <PopoverBody className="flex gap-2">
                                            <Menu>
                                                <MenuButton
                                                    as={Button}
                                                    size="sm"
                                                    rightIcon={<BiChevronDown />}
                                                >
                                                    {/* {this.state.selectFilterProgress} */}
                                                </MenuButton>
                                                <MenuList>
                                                    {/* {this.filterOperators.map(operator => (
                                                        <MenuItem
                                                            key={operator}
                                                            onClick={() =>
                                                                this.changeState(
                                                                    'selectFilterProgress',
                                                                    operator
                                                                )
                                                            }
                                                        >
                                                            {operator}
                                                        </MenuItem>
                                                    ))} */}
                                                </MenuList>
                                            </Menu>
                                            <Input
                                                className="w-14"
                                                // onChange={event =>
                                                //     this.changeState(
                                                //         'inputFilterProgress',
                                                //         event.target.value
                                                //     )
                                                // }
                                            />
                                            <Button
                                                size="sm"
                                                // onClick={this.filterProgress}
                                            >
                                                Apply
                                            </Button>
                                        </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            </div>
                        </th>
                        <th>Issue Category</th>
                        <th>Issue Type</th>
                        <th>Issue Area</th>
                    </tr>
                </thead>
                {taskGroups.map(group => (
                    <tbody key={group.createdOn}>
                        <tr>
                            <td colSpan={99}>
                                <div className="flex items-center rounded-md border border-primary-900 px-4 py-1">
                                    <p className="flex-1 font-semibold text-neutral-900">
                                        {parseDateString(group.createdOn)}
                                    </p>
                                </div>
                            </td>
                        </tr>
                        {group.tasks.map(task => (
                            <tr
                                key={task.ticketNO}
                                className="cursor-pointer border border-zinc-300 transition-colors duration-75 hover:bg-zinc-300 [&_*]:whitespace-nowrap [&_td]:px-4 [&_td]:py-1"
                                onClick={() => onClickTicketDetail(task)}
                            >
                                <td>
                                    <div className="flex items-center gap-2">
                                        <FaEnvelope size={16} />
                                        <span>{task.show_radius}</span>
                                    </div>
                                </td>
                                <td>{task.ticketNO}</td>
                                <td>{task.leaderEN}</td>
                                <td className="text-center">
                                    <span
                                        className={twMerge(
                                            'rounded-md border border-zinc-300 px-2',
                                            task.activityOverviewStatus === 'ถูกยกเลิก'
                                                ? 'border-red-600 bg-red-50 text-red-600'
                                                : task.activityOverviewStatus === 'ปิดงานแล้ว'
                                                ? 'border-green-600 bg-green-50 text-green-600'
                                                : task.activityOverviewStatus === 'กำลังดำเนินการ'
                                                ? 'border-blue-600 bg-blue-50 text-blue-600'
                                                : ''
                                        )}
                                    >
                                        {' '}
                                        {task.activityOverviewStatus}
                                    </span>
                                </td>
                                <td>{task.projectcategory}</td>
                                <td className="text-right">{task.taskStatusPercent}</td>
                                {task.additionalField.length !== 0
                                    ? ['category', 'type', 'area'].map((type, index) => {
                                          const additionalField = task.additionalField.find(field =>
                                              field.fieldName.toLowerCase().includes(type)
                                          );

                                          return (
                                              <td key={index}>{additionalField?.optionsName}</td>
                                          );
                                      })
                                    : Array.from({ length: 3 }).map((_, index) => (
                                          <td key={index}></td>
                                      ))}
                            </tr>
                        ))}
                    </tbody>
                ))}
            </table>
        </div>
    );
}

export default TicketTable;
