import axios from 'axios';
import { GetTokenRequest, GetTokenResponse } from '../schemas';

export async function get(params: GetTokenRequest) {
    return axios.get<GetTokenResponse>('/v1/token', {
        params,
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('SSO_AUTH')
        }
    });
}
