import { useCallback, useDeferredValue, useEffect, useMemo, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useBoolean } from '@chakra-ui/react';
import { useLocalStorage } from 'usehooks-ts';
import Swal from 'sweetalert2';

import { useLoader, useStore } from 'common/hooks';
import { Task } from 'models/link/Link';
import { ResponseError } from 'models';
import { TaskActivity } from 'models/link/Task';
import { ReplyEmailSupport } from 'models/user/UserSupport';
import {
    cancelTaskActivity,
    closeTaskActivity,
    getTaskActivity,
    getTaskLink
} from 'data/link/respositories/taskRepository';

import { TABS } from './Ticket.view';

function TicketViewModel() {
    const loader = useLoader();
    const { userCompanyStore } = useStore();
    const { email } = userCompanyStore;

    const [tasks, setTasks] = useState<Task[]>();
    const [filter, setFilter] = useState<(typeof TABS)[number]['value']>('all');
    const [search, setSearch] = useState('');
    const [displayStyle, setDisplayStyle] = useLocalStorage<'grid' | 'list'>(
        'SUPPORT-DISPLAY_TICKET_STYLE',
        'grid'
    );
    const [showSupportModal, setShowSupportModal] = useBoolean();
    const [selectedTask, setSelectedTask] = useState<Task>();
    const [selectedTaskDetail, setSelectedTaskDetail] = useState<TaskActivity>();
    const showTaskDetailModal = !!selectedTaskDetail;

    const deferredSearch = useDeferredValue(search);
    const deferredFilter = useDeferredValue(filter);
    const filteredTasks = useMemo(() => {
        if (!tasks) {
            return null;
        }

        const searchTerm = deferredSearch.toLowerCase();
        return tasks.filter(
            task =>
                task.activityOverviewStatus.includes(
                    deferredFilter === 'all' ? '' : deferredFilter
                ) &&
                (task.jobdescription.toLowerCase().includes(searchTerm) ||
                    task.ticketNO.toLowerCase().includes(searchTerm))
        );
    }, [tasks, deferredFilter, deferredSearch]);

    useEffect(() => {
        fetchTaskLink(userCompanyStore.email);
    }, []);

    const fetchTaskLink = async (email: string) => {
        try {
            loader.show();
            const [, data] = await getTaskLink(email);

            if (data === null) {
                return;
            }

            setTasks(data);
        } catch (error) {
            Swal.fire('Error!', (error as ResponseError).message, 'error');
        } finally {
            loader.hide();
        }
    };

    const onClickReply = useCallback(
        async (reply: ReplyEmailSupport) => {
            try {
                loader.show();

                await axios.post('/v1/user/support/reply', reply);

                Swal.fire('Success!', 'Reply successfully!', 'success');
            } catch (error) {
                Swal.fire('Error!', (error as AxiosError).response?.data as string, 'error');
            } finally {
                handleCloseTicketDetailModal();
                await handleClickTicketDetail(selectedTask!);
                loader.hide();
            }
        },
        [selectedTask]
    );

    const handleChangeSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }, []);

    const handleClickTicketDetail = useCallback(async (task: Task) => {
        try {
            loader.show();

            const [error, data] = await getTaskActivity({
                key: task.aobjectlink,
                link_id: task.empcode
            });
            if (error) {
                throw error;
            }

            setSelectedTask(task);
            setSelectedTaskDetail(data);
        } catch (error) {
            Swal.fire('Error!', (error as ResponseError).message, 'error');
        } finally {
            loader.hide();
        }
    }, []);

    const handleClickCloseTask = useCallback(async () => {
        try {
            loader.show();

            if (!selectedTaskDetail) {
                return;
            }

            const [error, data] = await closeTaskActivity(selectedTaskDetail.key);
            if (error) {
                throw new Error(error.message);
            }

            Swal.fire('Success!', 'ปิดงานสำเร็จ', 'success');
        } catch (error) {
            if (error instanceof Error) {
                Swal.fire('Error!', error.message, 'error');
            }
        } finally {
            handleCloseTicketDetailModal();
            await handleClickTicketDetail(selectedTask!);
            fetchTaskLink(userCompanyStore.email);
            loader.hide();
        }
    }, [selectedTaskDetail]);

    const handleClickCancelTask = useCallback(async () => {
        try {
            loader.show();

            if (!selectedTaskDetail) {
                return;
            }

            const [error, data] = await cancelTaskActivity(selectedTaskDetail.key);
            if (error) {
                throw new Error(error.message);
            }

            Swal.fire('Success!', 'ยกเลิกงานสำเร็จ', 'success');
        } catch (error) {
            if (error instanceof Error) {
                Swal.fire('Error!', error.message, 'error');
            }
        } finally {
            handleCloseTicketDetailModal();
            await handleClickTicketDetail(selectedTask!);
            fetchTaskLink(userCompanyStore.email);
            loader.hide();
        }
    }, [selectedTaskDetail]);

    const handleCloseTicketDetailModal = useCallback(() => {
        setSelectedTask(undefined);
        setSelectedTaskDetail(undefined);
    }, []);

    return {
        email,
        filter,
        displayStyle,
        filteredTasks,
        search,
        showSupportModal,
        selectedTask,
        selectedTaskDetail,
        showTaskDetailModal,
        setDisplayStyle,
        setFilter,
        handleChangeSearch,
        handleClickTicketDetail,
        handleCloseTicketDetailModal,
        handleClickCloseTask,
        handleClickCancelTask,
        setShowSupportModal,
        onClickReply
    };
}

export default TicketViewModel;
