import { parseResponseError } from '../../../common/helpers';
import {
    DatasourceReturnType,
    ResponseError
} from '../../../models';
import { GetTokenRequest } from '../schemas';
import { get } from '../datasources/token.datasource';

export async function getToken(
    params: GetTokenRequest
): Promise<DatasourceReturnType<ResponseError, string>> {
    try {
        const data = (await get(params)).data;

        return [null!, data.token];
    } catch (error) {
        const reason = parseResponseError(error);

        if (reason) {
            return [reason, null!];
        }

        throw error;
    }
}
